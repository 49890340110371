import { PeriodOption } from '@/config/model';
import {
    Interval,
    addWeeks,
    eachDayOfInterval,
    eachMonthOfInterval,
    eachQuarterOfInterval,
    eachWeekOfInterval,
    eachYearOfInterval,
    format,
    parse,
    startOfISOWeek,
} from 'date-fns';

interface DateFormatter {
    format: string;
    intervalGeneratorFn: (interval: Interval) => Date[];
    parserFn: (date: string) => Date;
}

const getPeriodDateFormatter = (period: PeriodOption): DateFormatter => {
    switch (period) {
        case 'yearly':
            return {
                format: 'yyyy',
                intervalGeneratorFn: eachYearOfInterval,
                parserFn: (date) => parse(date, 'yyyy', new Date()),
            };
        case 'quarterly':
            return {
                format: 'QQQ-yyyy',
                intervalGeneratorFn: eachQuarterOfInterval,
                parserFn: (date) => parse(date, 'QQQ-yyyy', new Date()),
            };
        case 'monthly':
            return {
                format: 'MM-yyyy',
                intervalGeneratorFn: eachMonthOfInterval,
                parserFn: (date) => parse(date, 'MM-yyyy', new Date()),
            };
        case 'daily':
            return {
                format: 'dd-MM-yyyy',
                intervalGeneratorFn: eachDayOfInterval,
                parserFn: (date) => parse(date, 'dd-MM-yyyy', new Date()),
            };
        default: //weekly
            return {
                format: 'ww-yyyy',
                intervalGeneratorFn: eachWeekOfInterval,
                parserFn: (date: string) => {
                    const [week, year] = date.split('-').map(Number);

                    const firstISOWeek = startOfISOWeek(
                        new Date(Number(year), 0, 4),
                    );

                    return addWeeks(firstISOWeek, Number(week) - 1);
                },
            };
    }
};

export const fillDateGaps = (
    dates: string[],
    period: PeriodOption,
): string[] => {
    if (dates.length === 0) return dates;

    const periodDateFormat = getPeriodDateFormatter(period);
    const parsedDates = dates.map((date: string) =>
        periodDateFormat.parserFn(date).getTime(),
    );

    const minDate = new Date(Math.min(...parsedDates));
    const maxDate = new Date(Math.max(...parsedDates));

    const allDates = periodDateFormat.intervalGeneratorFn({
        start: minDate,
        end: maxDate,
    });

    return allDates.map((date) => format(date, periodDateFormat.format));
};
