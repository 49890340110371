import { Table } from '@/components/Table';
import { WithLoader } from '@/components/WithLoader';
import { useConfigContext } from '@/context/configContext';
import {
    useSpendSummaryContext,
    useSpendSummaryQuery,
} from '@/dashboards/SpendSummary/hooks';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { cn } from '@/utils';
import { roundToDecimals } from '@/utils/numberUtils';
import { Tooltip } from '@analytical-alley/ui';
import { IconExclamationCircle } from '@tabler/icons-react';
import { CellContext, Row, createColumnHelper } from '@tanstack/react-table';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { getROIConfig } from './utils';

interface TableProps {
    category: string;
    variable?: string;
    contribution: number;
    spend: number;
    contributionPercentageTotal: number;
    roi: number;
}

type NumericTableProps = {
    [K in keyof TableProps]: TableProps[K] extends number ? K : never;
}[keyof TableProps];

export const TableCellRenderer = <T extends TableProps, E extends ReactNode>({
    getValue,
    className,
    children,
}: CellContext<T, E> & {
    className?: string;
    children?: ReactNode;
    disabled?: boolean;
}) => {
    return (
        <span className={cn('flex items-center px-3.5 py-2 h-14', className)}>
            {children || getValue()}
        </span>
    );
};

export const SpendSummaryTableSection = () => {
    const { formatNumber } = useConfigContext();
    const {
        project: { roiType },
    } = useDashboardGlobalContext();

    const { name, calculate, description } = useMemo(
        () => getROIConfig(roiType),
        [roiType],
    );

    const columnHelper = createColumnHelper<TableProps>();
    const {
        queryResult: { isLoading, isFetching },
    } = useSpendSummaryQuery();
    const { modelBreakdown, spendSummaryData, selectedFilterNodes } =
        useSpendSummaryContext();
    const isModelBreakdownCategory = modelBreakdown === 'category';

    const totalRoi = calculate({
        spend: spendSummaryData.totalSpend,
        contribution: spendSummaryData.totalContribution,
        contributionCostProduct: spendSummaryData.totalContributionProduct,
    });

    const getTableDataByCategory = useCallback(() => {
        let totalContribution = 0;
        let totalSpend = 0;
        let totalContributionProduct = 0;

        const filteredData = spendSummaryData.categoryData
            .filter((d) => {
                return !!selectedFilterNodes[d.category];
            })
            .map((data) => {
                totalContribution +=
                    spendSummaryData.totalContributionByCategory.get(
                        data.category,
                    )!;
                totalSpend += spendSummaryData.totalSpendByCategory.get(
                    data.category,
                )!;
                totalContributionProduct +=
                    spendSummaryData.totalContributionProductByCategory.get(
                        data.category,
                    )!;

                return {
                    category: data.category,
                    contribution:
                        spendSummaryData.totalContributionByCategory.get(
                            data.category,
                        )!,
                    spend: spendSummaryData.totalSpendByCategory.get(
                        data.category,
                    )!,
                    contributionPercentageTotal: Number(
                        (spendSummaryData.totalContributionByCategory.get(
                            data.category,
                        )! /
                            spendSummaryData.totalContribution) *
                            100,
                    ),
                    roi: calculate({
                        spend: spendSummaryData.totalSpendByCategory.get(
                            data.category,
                        )!,
                        contribution:
                            spendSummaryData.totalContributionByCategory.get(
                                data.category,
                            )!,
                        contributionCostProduct:
                            spendSummaryData.totalContributionProductByCategory.get(
                                data.category,
                            )!,
                    }),
                };
            })
            .sort((a, b) => {
                const totalContributionA =
                    spendSummaryData.totalContributionByCategory.get(
                        a.category,
                    )!;
                const totalContributionB =
                    spendSummaryData.totalContributionByCategory.get(
                        b.category,
                    )!;
                if (totalContributionA < totalContributionB) {
                    return 1;
                }
                if (totalContributionA > totalContributionB) {
                    return -1;
                }

                return b.contribution - a.contribution;
            });

        return {
            data: filteredData,
            totalRoiFiltered: calculate({
                spend: totalSpend,
                contribution: totalContribution,
                contributionCostProduct: totalContributionProduct,
            }),
        };
    }, [
        calculate,
        selectedFilterNodes,
        spendSummaryData.categoryData,
        spendSummaryData.totalContribution,
        spendSummaryData.totalContributionByCategory,
        spendSummaryData.totalContributionProductByCategory,
        spendSummaryData.totalSpendByCategory,
    ]);

    const getTableDataByVariable = useCallback(() => {
        let totalContribution = 0;
        let totalSpend = 0;
        let totalContributionProduct = 0;

        const filteredData = spendSummaryData.variableData
            .filter((d) => {
                return (
                    !!selectedFilterNodes[d.category] ||
                    selectedFilterNodes[d.variable]
                );
            })
            .map((data) => {
                totalContribution +=
                    spendSummaryData.totalContributionByVariable.get(
                        data.variable,
                    )!;
                totalSpend += spendSummaryData.totalSpendByVariable.get(
                    data.variable,
                )!;
                totalContributionProduct +=
                    spendSummaryData.totalContributionProductByVariable.get(
                        data.variable,
                    )!;
                return {
                    category: data.category,
                    variable: data.variable,
                    contribution:
                        spendSummaryData.totalContributionByVariable.get(
                            data.variable,
                        )!,
                    spend: spendSummaryData.totalSpendByVariable.get(
                        data.variable,
                    )!,
                    contributionPercentageTotal: Number(
                        (spendSummaryData.totalContributionByVariable.get(
                            data.variable,
                        )! /
                            spendSummaryData.totalContribution) *
                            100,
                    ),
                    roi: calculate({
                        spend: spendSummaryData.totalSpendByVariable.get(
                            data.variable,
                        )!,
                        contribution:
                            spendSummaryData.totalContributionByVariable.get(
                                data.variable,
                            )!,
                        contributionCostProduct:
                            spendSummaryData.totalContributionProductByVariable.get(
                                data.variable,
                            )!,
                    }),
                };
            })
            .filter((d) => {
                return d.contribution + d.spend > 0;
            })
            .sort((a, b) => {
                const totalContributionA =
                    spendSummaryData.totalContributionByCategory.get(
                        a.category,
                    )!;
                const totalContributionB =
                    spendSummaryData.totalContributionByCategory.get(
                        b.category,
                    )!;
                if (totalContributionA < totalContributionB) {
                    return 1;
                }
                if (totalContributionA > totalContributionB) {
                    return -1;
                }

                return b.contribution - a.contribution;
            });

        return {
            data: filteredData,
            totalRoiFiltered: calculate({
                spend: totalSpend,
                contribution: totalContribution,
                contributionCostProduct: totalContributionProduct,
            }),
        };
    }, [
        calculate,
        selectedFilterNodes,
        spendSummaryData.totalContribution,
        spendSummaryData.totalContributionByCategory,
        spendSummaryData.totalContributionByVariable,
        spendSummaryData.totalContributionProductByVariable,
        spendSummaryData.totalSpendByVariable,
        spendSummaryData.variableData,
    ]);

    const { data, totalRoiFiltered } = useMemo(
        () =>
            isModelBreakdownCategory
                ? getTableDataByCategory()
                : getTableDataByVariable(),
        [
            getTableDataByCategory,
            getTableDataByVariable,
            isModelBreakdownCategory,
        ],
    );

    const calculateSum = useCallback(
        <Key extends NonNullable<NumericTableProps>>(
            rows: Row<TableProps>[],
            id: Key,
        ): number => {
            return rows.reduce((sum, row) => sum + row.original[id], 0);
        },
        [],
    );

    const columns = useMemo(
        () => [
            columnHelper.accessor('category', {
                header: 'Category',
                cell: (info) => {
                    return <TableCellRenderer {...info} />;
                },
                footer: 'Total',
                meta: {
                    header: {
                        className: 'h-14',
                    },
                    footer: {
                        className: isModelBreakdownCategory
                            ? 'text-left'
                            : 'text-right',
                    },
                },
            }),
            columnHelper.accessor('contribution', {
                header: 'Contribution',
                cell: (info) => {
                    return (
                        <TableCellRenderer {...info} className="justify-center">
                            {formatNumber(info.getValue())}
                        </TableCellRenderer>
                    );
                },
                footer: (props) =>
                    formatNumber(
                        calculateSum(
                            props.table.getRowModel().rows,
                            'contribution',
                        ),
                    ),
                meta: {
                    header: {
                        className: 'text-center',
                    },
                    footer: {
                        className: 'text-center',
                    },
                },
            }),
            columnHelper.accessor('spend', {
                header: 'Spend',
                cell: (info) => {
                    return (
                        <TableCellRenderer {...info} className="justify-center">
                            {formatNumber(info.getValue())}
                        </TableCellRenderer>
                    );
                },
                footer: (props) => {
                    return formatNumber(
                        calculateSum(props.table.getRowModel().rows, 'spend'),
                    );
                },
                meta: {
                    header: {
                        className: 'text-center',
                    },
                    footer: {
                        className: 'text-center',
                    },
                },
            }),
            columnHelper.accessor('contributionPercentageTotal', {
                header: 'Contribution % total',
                cell: (info) => {
                    return (
                        <TableCellRenderer {...info} className="justify-center">
                            {info.getValue().toFixed(2)}%
                        </TableCellRenderer>
                    );
                },
                footer: (props) =>
                    `${roundToDecimals(calculateSum(props.table.getRowModel().rows, 'contributionPercentageTotal'))}%`,
                meta: {
                    header: {
                        className: 'text-center',
                    },
                    footer: {
                        className: 'text-center',
                    },
                },
            }),
            columnHelper.accessor('roi', {
                header: 'ROI',
                cell: (info) => {
                    return (
                        <TableCellRenderer
                            {...info}
                            className="justify-center"
                        />
                    );
                },
                footer: () => totalRoiFiltered,
                meta: {
                    header: {
                        className: 'text-center',
                    },
                    footer: {
                        className: 'text-center',
                    },
                },
            }),
        ],
        [
            calculateSum,
            columnHelper,
            formatNumber,
            isModelBreakdownCategory,
            totalRoiFiltered,
        ],
    );

    if (!isModelBreakdownCategory) {
        const variableColumn = columnHelper.accessor('variable', {
            header: 'Variable',
            cell: (info) => {
                return (
                    <TableCellRenderer {...info} className="justify-center" />
                );
            },
            meta: {
                header: {
                    className: 'text-center',
                },
            },
        });

        // Insert variableColumn at the second position in the columns array
        columns.splice(1, 0, variableColumn);
    }

    return (
        <div className="glass-tile flex flex-col p-8 max-lg:px-5 max-lg:mt-10 max-lg:max-w-full">
            <WithLoader isLoading={isLoading} isFetching={isFetching}>
                <div className="font-mono flex flex-row justify-stretch max-lg:flex-wrap gap-4 mb-6">
                    <div className="glass-tile flex flex-col flex-1 justify-start items-center gap-4 px-6 py-4">
                        <span className="flex flex-row flex-wrap justify-center font-medium">
                            <span>Total&nbsp;</span>
                            <span>Media Contribution</span>
                        </span>
                        <span className="text-[32px] font-normal leading-none">
                            {formatNumber(spendSummaryData.totalContribution)}
                        </span>
                    </div>
                    <div className="glass-tile flex flex-col flex-1 justify-start items-center gap-4 px-6 py-4">
                        <span className="flex flex-row flex-wrap justify-center font-medium">
                            <span>Total&nbsp;</span>
                            <span>Media Spend</span>
                        </span>
                        <span className="text-[32px] font-normal leading-none">
                            {formatNumber(spendSummaryData.totalSpend)}
                        </span>
                    </div>
                    <div className="glass-tile flex flex-col flex-1 justify-start items-center gap-4 px-6 py-4">
                        <span className="flex gap-1 font-medium">
                            <span className="flex flex-row flex-wrap justify-center font-medium">
                                <span>Total&nbsp;</span>
                                <span>{`Media ${name}`}</span>
                            </span>
                            <Tooltip>
                                <Tooltip.Trigger>
                                    <span className="cursor-pointer">
                                        <IconExclamationCircle className="w-5" />
                                    </span>
                                </Tooltip.Trigger>
                                <Tooltip.Content className="tooltip-content">
                                    {description}
                                </Tooltip.Content>
                            </Tooltip>
                        </span>
                        <span className="text-[32px] font-normal leading-none">
                            {totalRoi}
                        </span>
                    </div>
                </div>
                <Table
                    columns={columns}
                    shouldSpanRow
                    colSpan={
                        isModelBreakdownCategory
                            ? undefined
                            : {
                                  footer: { value: 2, index: 0 },
                              }
                    }
                    data={data}
                />
            </WithLoader>
        </div>
    );
};
