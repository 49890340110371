import { useBarChartContext } from '@/components/ShadowBarChart/context';
import { KEY_EVENT_KEYS } from '@/config/constants';
import { cn, twCn } from '@/utils';
import { Tooltip } from '@analytical-alley/ui';
import React, { ReactNode } from 'react';

export const ShadowBarGroupTitle = ({
    children,
    width,
    active,
    wrapTitle,
    onClick,
    activeDotColor,
}: {
    children: ReactNode;
    width: number;
    wrapTitle?: boolean;
    active?: boolean;
    onClick?: () => void;
    activeDotColor?: string;
}) => {
    const { showTickMarks, options } = useBarChartContext();

    const isRotated = options.labelVariant === 'rotated';

    return (
        <Tooltip placement={'bottom'}>
            <div
                style={{ width: width }}
                className={cn('min-w-14', {
                    'mt-1': showTickMarks,
                    'self-center': !isRotated,
                })}
            >
                <div
                    className={cn('absolute left-0 invisible', {
                        'w-24': isRotated,
                    })}
                >
                    {children}
                </div>
                <Tooltip.Trigger asChild>
                    <div
                        onKeyDown={
                            onClick
                                ? (e) => {
                                      if (
                                          e.key === KEY_EVENT_KEYS.SPACE ||
                                          e.key === KEY_EVENT_KEYS.ENTER
                                      ) {
                                          onClick();
                                      }
                                  }
                                : undefined
                        }
                        role={onClick ? 'button' : undefined}
                        onClick={onClick}
                        style={
                            isRotated
                                ? {
                                      position: 'relative',
                                      transform: 'translateX(-50%)',
                                      left: '50%',
                                      rotate: '-45deg',
                                      transformOrigin: '25px 35px 0px',
                                  }
                                : undefined
                        }
                        className={twCn('mx-0.5', {
                            'flex items-center rounded-lg shadow-lg dark:bg-white bg-gray-300 bg-opacity-80 dark:bg-opacity-20 p-2':
                                active,
                            'w-24': isRotated,
                            'cursor-pointer hover:bg-opacity-30 dark:hover:bg-opacity-30':
                                !!onClick,
                            'justify-center': !isRotated,
                        })}
                    >
                        <div
                            className={twCn(
                                'text-center text-dark dark:text-white',
                                {
                                    'text-center': active,
                                    'w-20 ': isRotated,
                                    'text-end': !active && isRotated,
                                    'text-pretty': isRotated,
                                    'break-all': wrapTitle,
                                    truncate: !wrapTitle,
                                },
                            )}
                        >
                            {children}
                        </div>
                        {active && (
                            <div
                                style={{
                                    backgroundColor: activeDotColor || 'white',
                                }}
                                className="w-2 h-2 ml-1 rounded-full flex-shrink-0"
                            />
                        )}
                    </div>
                </Tooltip.Trigger>
            </div>
            <Tooltip.Content>
                <div className="tooltip-content-sm">{children}</div>
            </Tooltip.Content>
        </Tooltip>
    );
};
