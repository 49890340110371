import { getColor } from '@/components/ECharts/colors';
import { EChartsOption } from '@/components/ECharts/useChart';
import { ROICurvesChartProps } from '@/dashboards/BudgetOptimization/components/ROICurves/ROICurvesChart';
import { RoiData } from '@/dashboards/BudgetOptimization/hooks/useBudgetOptimizationData';
import { LineSeriesOption } from 'echarts';

type ChartOptions = EChartsOption<LineSeriesOption>;

export const getEChartsOptions = (
    data: RoiData,
    points: ROICurvesChartProps['points'],
    investmentStep: number,
    opt: { formatNumber: (value: number) => string },
): ChartOptions => {
    const curves = data?.curves || {};
    const xAxis = data?.xAxis || [];

    const { optimalAllocation, previousAllocation } = points;

    return {
        animation: false,
        tooltip: {
            trigger: 'axis',
            triggerOn: 'mousemove',
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            type: 'item',
            formatter: '',
        },
        dataZoom: [
            {
                show: false,
                type: 'inside',
                start: 0,
                end: 80,
            },
            {
                show: false,
                start: 0,
                end: 100,
            },
        ],
        legend: {
            showCustom: true,
        },
        grid: {
            top: '5%',
            left: '8%',
            right: '4%',
            containLabel: true,
        },
        xAxis: {
            axisTick: {
                show: true,
            },
            name: 'Investments',
            nameLocation: 'middle',
            nameGap: 30,
            nameTextStyle: {
                fontSize: '0.75rem',
            },
            axisLabel: {
                formatter: (value: string) => {
                    return opt.formatNumber(parseInt(value));
                },
            },
            type: 'category',
            min: 0,
            data: xAxis,
        },
        yAxis: {
            axisTick: {
                show: true,
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#B0C2F53F',
                },
            },
            name: 'Contribution',
            nameLocation: 'middle',
            nameGap: 100,
            nameTextStyle: {
                fontSize: '0.75rem',
            },
            min: 'dataMin',
            max: 'dataMax',
            type: 'value',
            axisLabel: {
                formatter: (value: string) => {
                    return opt.formatNumber(parseInt(value));
                },
            },
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        series: Object.entries(curves).map(([key, value], index) => {
            const previousAllocationMarkPoint = previousAllocation
                ? {
                      name: key,
                      coord: [
                          previousAllocation[key]!.toString(),
                          value[previousAllocation[key]! / investmentStep],
                      ],
                      symbolSize: 12,
                      symbol: 'circle',
                  }
                : {};

            const optimalAllocationMarkPoint = optimalAllocation
                ? {
                      name: key,
                      coord: [
                          optimalAllocation[key]!.optimalSpend.toString(),
                          value[
                              optimalAllocation[key]!.optimalSpend /
                                  investmentStep
                          ],
                      ],
                      symbolSize: 12,
                      symbol: 'path://M4.59532 6L2.00002 7.47175L2.70224 8.66641L5.33333 7.17437V10H6.66667V7.17463L9.29776 8.66667L9.99998 7.47201L7.40423 6L10 4.52799L9.29777 3.33333L6.66667 4.82538V2H5.33333V4.82564L2.70223 3.33359L2 4.52825L4.59532 6Z',
                  }
                : {};

            return {
                smooth: true,
                name: key,
                type: 'line',
                symbolSize: 1,
                color: getColor(index),
                markPoint: {
                    itemStyle: {
                        opacity: 0.1,
                    },
                    data: [
                        previousAllocationMarkPoint,
                        optimalAllocationMarkPoint,
                    ],
                },
                data: value,
            };
        }),
    };
};
