import { GetPreviousDataReturn } from '@/dashboards/BudgetOptimization/api';
import { UseVariableDataReturn } from '@/dashboards/BudgetOptimization/hooks/useVariableData';

export const structureBudgetData = ({
    variablesAndCategories,
    totals,
    optimizedData,
}: Pick<UseVariableDataReturn, 'optimizedData'> &
    Pick<GetPreviousDataReturn['roiCurves'], 'variablesAndCategories'> &
    Pick<GetPreviousDataReturn, 'totals'>) => {
    const budgetByCategoryData: {
        previous: { budget: number; percentage: number; name: string }[];
        simulated: { budget: number; percentage: number; name: string }[];
    } = {
        previous: [],
        simulated: [],
    };

    const suggestedBudgetData: {
        category: string;
        variable: string;
        previousPeriodInvestments: number;
        recommendedBudget: number;
        contribution: number;
    }[] = [];

    const budgetByVariableData: {
        [key: string]: {
            previousBudget: number;
            simulatedBudget: number;
            variable: string;
            category: string;
        }[];
    } = {};

    const expectedContributionByCategoryData: {
        [key: string]: number;
    } = {};

    let previousPeriodInvestmentTotal = 0;
    let simulatedInvestmentTotal = 0;
    let contributionTotal = 0;

    let highestVariableValue = 0;
    let highestCategoryValue = 0;

    Object.entries(variablesAndCategories.byCategory).forEach(
        ([categoryName, variables]) => {
            budgetByVariableData[categoryName] = [];

            let previousCategoryTotal = 0;
            let simulatedCategoryTotal = 0;

            variables.forEach((variable) => {
                highestVariableValue = Math.max(
                    highestVariableValue,
                    totals[variable] || 0,
                );
                previousCategoryTotal += totals[variable] || 0;
                const opt = optimizedData?.optimalVariables[variable];
                highestVariableValue = Math.max(
                    highestVariableValue,
                    opt?.optimalSpend || 0,
                );
                simulatedCategoryTotal += opt?.optimalSpend || 0;
                budgetByVariableData[categoryName]!.push({
                    category: categoryName,
                    variable,
                    previousBudget: totals[variable] || 0,
                    simulatedBudget:
                        optimizedData?.optimalVariables[variable]
                            ?.optimalSpend || 0,
                });

                previousPeriodInvestmentTotal += totals[variable] || 0;
                simulatedInvestmentTotal += opt?.optimalSpend || 0;
                contributionTotal += opt?.kpi || 0;

                suggestedBudgetData.push({
                    category: categoryName,
                    variable,
                    previousPeriodInvestments: totals[variable] || 0,
                    recommendedBudget: opt?.optimalSpend || 0,
                    contribution: opt?.kpi || 0,
                });

                if (!expectedContributionByCategoryData[categoryName]) {
                    expectedContributionByCategoryData[categoryName] = 0;
                }
                expectedContributionByCategoryData[categoryName] +=
                    opt?.kpi || 0;
            });

            highestCategoryValue = Math.max(
                highestCategoryValue,
                previousCategoryTotal,
            );

            const diff = simulatedCategoryTotal - previousCategoryTotal;
            const percentage = Math.round((diff / previousCategoryTotal) * 100);

            budgetByCategoryData.previous.push({
                budget: previousCategoryTotal,
                percentage,
                name: categoryName,
            });
            budgetByCategoryData.simulated.push({
                budget: simulatedCategoryTotal,
                percentage,
                name: categoryName,
            });
        },
    );

    return {
        budgetByCategoryData,
        suggestedBudgetData,
        budgetByVariableData,
        highestCategoryValue,
        highestVariableValue,
        previousPeriodInvestmentTotal,
        simulatedInvestmentTotal,
        contributionTotal,
        expectedContributionByCategoryData,
    };
};
