import { PercentItem } from '@/components/PercentItem';
import {
    ShadowBar,
    ShadowBarChart,
    ShadowBarGroup,
} from '@/components/ShadowBarChart';
import { useConfigContext } from '@/context/configContext';
import { useSpendSummaryContext } from '@/dashboards/SpendSummary/hooks';
import { twCn } from '@/utils';
import { roundToDecimals } from '@/utils/numberUtils';
import { capitalizeFirstLetter } from '@/utils/stringUtils';
import { Typography } from '@analytical-alley/ui';
import { useMemo } from 'react';

type ChartDataVariable = {
    name: string;
    contribution: number;
    spend: number;
    contributionVsSpendPercent: number;
    contributionSharePercentage: number;
    spendSharePercentage: number;
};

type ChartData = {
    [key: string]: ChartDataVariable[];
};

const HEIGHT = 300;

export const MediaSpendVsContributionShareGraph = () => {
    const { formatNumber } = useConfigContext();
    const { spendSummaryData, selectedFilterNodes } = useSpendSummaryContext();

    const totalContributionValue = spendSummaryData.totalContribution;
    const totalSpendValue = spendSummaryData.totalSpend;

    const { chartData, scale }: { chartData: ChartData; scale: number } =
        useMemo(() => {
            let scale = 0;
            const chartDataObj: { [key: string]: ChartDataVariable[] } = {};

            spendSummaryData.variableData
                .filter((d) => {
                    return (
                        !!selectedFilterNodes[d.category] ||
                        selectedFilterNodes[d.variable]
                    );
                })
                .filter((d) => {
                    const contribution =
                        spendSummaryData.totalContributionByVariable.get(
                            d.variable,
                        )!;
                    const spend = spendSummaryData.totalSpendByVariable.get(
                        d.variable,
                    )!;

                    return contribution + spend > 0;
                })
                .forEach((data) => {
                    const contribution =
                        spendSummaryData.totalContributionByVariable.get(
                            data.variable,
                        )!;
                    const spend = spendSummaryData.totalSpendByVariable.get(
                        data.variable,
                    )!;

                    const contributionSharePercentage = roundToDecimals(
                        (contribution / totalContributionValue) * 100,
                        0,
                    );
                    const spendSharePercentage = roundToDecimals(
                        (spend / totalSpendValue) * 100,
                        0,
                    );

                    const contributionVsSpendPercent = roundToDecimals(
                        contributionSharePercentage - spendSharePercentage,
                        0,
                    );

                    scale = Math.max(
                        scale,
                        contributionVsSpendPercent,
                        spendSharePercentage,
                        contributionSharePercentage,
                    );

                    const entry = {
                        name: data.variable,
                        contribution,
                        spend,
                        contributionSharePercentage,
                        spendSharePercentage,
                        contributionVsSpendPercent,
                    };
                    const shortCategoryName = data.category.split(' ')[0] || '';

                    if (chartDataObj[shortCategoryName]) {
                        chartDataObj[shortCategoryName]?.push(entry);
                    } else {
                        chartDataObj[shortCategoryName] = [];
                        chartDataObj[shortCategoryName]?.push(entry);
                    }
                });

            return { chartData: chartDataObj, scale };
        }, [
            spendSummaryData.variableData,
            spendSummaryData.totalContributionByVariable,
            spendSummaryData.totalSpendByVariable,
            selectedFilterNodes,
            totalContributionValue,
            totalSpendValue,
        ]);

    const dataLength = Object.keys(chartData).length;

    return (
        <div className="relative">
            <div
                className={twCn(`grid flex-wrap justify-around gap-3`)}
                style={{
                    gridTemplateColumns: `repeat(${dataLength}, minmax(0, 1fr))`,
                }}
            >
                {Object.entries(chartData).map(([key, entry]) => {
                    return (
                        <div key={key} className="grow">
                            <ShadowBarChart height={HEIGHT} maxValue={scale}>
                                <ShadowBarChart.Title>
                                    {capitalizeFirstLetter(key)}
                                </ShadowBarChart.Title>
                                <ShadowBarChart.Content>
                                    {entry.map(
                                        (
                                            {
                                                name,
                                                contribution,
                                                spend,
                                                contributionVsSpendPercent,
                                                spendSharePercentage,
                                                contributionSharePercentage,
                                            }: ChartDataVariable,
                                            index,
                                        ) => {
                                            return (
                                                <ShadowBarGroup
                                                    key={index}
                                                    title={name}
                                                    tooltipContent={
                                                        <div className="tooltip-content">
                                                            <table className="border-collapse">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="pr-2">
                                                                            <Typography className="text-black dark:text-black">
                                                                                Contribution:
                                                                            </Typography>
                                                                        </td>
                                                                        <td>
                                                                            <Typography className="text-[#2B67FF] dark:text-[#2B67FF]">
                                                                                {formatNumber(
                                                                                    contribution,
                                                                                )}
                                                                            </Typography>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="pr-2 pb-4">
                                                                            <Typography className="text-black dark:text-black">
                                                                                Contribution
                                                                                Share
                                                                                %:
                                                                            </Typography>
                                                                        </td>
                                                                        <td>
                                                                            <Typography className="text-[#2B67FF] dark:text-[#2B67FF] pb-4">
                                                                                {Math.round(
                                                                                    (contribution /
                                                                                        totalContributionValue) *
                                                                                        100,
                                                                                )}

                                                                                %
                                                                            </Typography>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="pr-2">
                                                                            <Typography className="text-black dark:text-black">
                                                                                Spend:
                                                                            </Typography>
                                                                        </td>
                                                                        <td>
                                                                            <Typography className="text-indigo-300 dark:text-indigo-300 text-opacity-10">
                                                                                {formatNumber(
                                                                                    spend,
                                                                                )}
                                                                            </Typography>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="pr-2">
                                                                            <Typography className="text-black dark:text-black">
                                                                                Spend
                                                                                Share
                                                                                %:
                                                                            </Typography>
                                                                        </td>
                                                                        <td>
                                                                            <Typography className="text-indigo-300 dark:text-indigo-300 text-opacity-10">
                                                                                {Math.round(
                                                                                    (spend /
                                                                                        totalSpendValue) *
                                                                                        100,
                                                                                )}

                                                                                %
                                                                            </Typography>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    }
                                                >
                                                    <ShadowBar
                                                        height={
                                                            spendSharePercentage
                                                        }
                                                        variant="secondary"
                                                    />
                                                    <ShadowBar
                                                        height={
                                                            contributionSharePercentage
                                                        }
                                                        variant="primary"
                                                    >
                                                        <ShadowBar.Title>
                                                            <PercentItem
                                                                percent={
                                                                    contributionVsSpendPercent
                                                                }
                                                            />
                                                        </ShadowBar.Title>
                                                    </ShadowBar>
                                                </ShadowBarGroup>
                                            );
                                        },
                                    )}
                                </ShadowBarChart.Content>
                            </ShadowBarChart>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
