import { Select } from '@/components/Select';
import { SelectRef } from '@/components/Select/Select';
import { ModelContributionsSearch } from '@/dashboards/ModelContributions';
import { DateRange } from '@/dashboards/ModelContributions/components/ActionBar/DateRange';
import {
    Filter,
    FilterNode,
} from '@/dashboards/ModelContributions/components/ActionBar/Filter';
import { useModelContributionContext } from '@/dashboards/ModelContributions/hooks';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import { calculateMaxDate } from '@/utils/calculateMaxDate';
import { Label } from 'flowbite-react';
import moment from 'moment/moment';
import { useCallback, useEffect, useMemo, useRef } from 'react';

const STORAGE_KEY = 'modelContributionsSearch';

export const ActionBar = () => {
    const { search, setSearch } = useModelContributionContext();

    const {
        startDate,
        endDate,
        project: { interval },
    } = useDashboardGlobalContext();

    const storedSearchString = window.sessionStorage.getItem(STORAGE_KEY);
    const storedSearch: ModelContributionsSearch = useMemo(
        () => (storedSearchString ? JSON.parse(storedSearchString) : {}),
        [storedSearchString],
    );

    const onSetSearch = (newSearch: Partial<ModelContributionsSearch>) => {
        setSearch(newSearch);
        window.sessionStorage.setItem(
            STORAGE_KEY,
            JSON.stringify({
                ...storedSearch,
                ...newSearch,
            }),
        );
    };

    const { contributionData, setSelectedNodes } =
        useModelContributionContext();
    const selectRef = useRef<SelectRef>(null);

    const onSetSelectedNodes = (nodes: FilterNode[]) => {
        setSelectedNodes(nodes);
    };

    const nodes = useMemo(() => {
        return search.modelBreakdown === 'category'
            ? contributionData.treeNodes.map((node) => ({
                  ...node,
                  children: [],
              }))
            : contributionData.treeNodes;
    }, [contributionData.treeNodes, search.modelBreakdown]);

    const minDate = moment.utc(startDate, 'DD-MM-YYYY').toDate();
    const maxDate = calculateMaxDate(endDate, interval);

    const canReset = useMemo(
        () =>
            (storedSearch.startDate && storedSearch.startDate !== startDate) ||
            (storedSearch.endDate && storedSearch.endDate !== endDate),
        [endDate, startDate, storedSearch.endDate, storedSearch.startDate],
    );

    useEffect(() => {
        if (search.startDate === startDate && storedSearch.startDate) {
            setSearch({
                startDate: storedSearch.startDate,
            });
        }

        if (search.endDate === endDate && storedSearch.endDate) {
            setSearch({
                endDate: storedSearch.endDate,
            });
        }
    }, [search, setSearch, startDate, endDate, storedSearch]);

    const onResetClick = useCallback(() => {
        window.sessionStorage.removeItem(STORAGE_KEY);
        setSearch({
            startDate: undefined,
            endDate: undefined,
            filter: undefined,
        });
    }, [setSearch]);

    return (
        <>
            <div className="flex gap-4">
                {canReset && (
                    <button
                        color="light"
                        className="glass-tile self-end p-1.5 rounded-md shadow-lg hover:bg-opacity-30"
                        onClick={onResetClick}
                    >
                        Reset
                    </button>
                )}
                <DateRange
                    minDate={minDate}
                    maxDate={maxDate}
                    searchStartDate={search.startDate}
                    searchEndDate={search.endDate}
                    onSetSearch={onSetSearch}
                />
            </div>
            <div className="flex gap-4">
                <div>
                    <div className="mb-1 block">
                        <Label
                            onClick={() => {
                                selectRef?.current?.click?.();
                            }}
                            className="font-mono font-normal"
                            htmlFor="modelBreakdown"
                            value="Model breakdown"
                        />
                    </div>
                    <Select
                        triggerRef={selectRef}
                        className="min-w-32"
                        selectedValue={search.modelBreakdown}
                        onSelectChange={(value) => {
                            setSearch({
                                modelBreakdown: value,
                            });
                        }}
                        dismissOnClick
                        label="Select..."
                        compact
                    >
                        <Select.Option
                            label={'By Category'}
                            value={'category'}
                        />
                        <Select.Option
                            label={'By Variable'}
                            value={'variable'}
                        />
                    </Select>
                </div>
                <div className="self-end">
                    <Filter
                        nodes={nodes}
                        onSetSelectedNodes={onSetSelectedNodes}
                        searchFilter={search.filter}
                        onSetSearch={onSetSearch}
                    />
                </div>
            </div>
        </>
    );
};
