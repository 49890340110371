import { ChangedBudgetItem } from '@/components/ChangedBudgetItem';
import { PercentItem } from '@/components/PercentItem';
import { cn } from '@/utils';
import { Typography } from '@analytical-alley/ui';
import React from 'react';

interface Props {
    title: string;
    value: number | string;
    percentage?: number | undefined;
    absChangeValue?: number;
    className?: string;
}

export const CurrencyBlock = ({
    percentage,
    value,
    absChangeValue,
    title,
    className,
}: Props) => {
    return (
        <div
            className={cn(
                'self-stretch h-[8rem] flex flex-col items-center justify-around rounded-lg dark:bg-[#FBFEFF21] px-6 py-4',
                className,
            )}
        >
            <Typography
                variant="titleS"
                responsive={false}
                className="text-[1.437rem] text-nowrap"
            >
                {title}
            </Typography>
            <Typography
                variant="titleM"
                responsive={false}
                className="text-[2rem] font-mono"
            >
                {value}
            </Typography>
            {typeof percentage === 'number' && (
                <span className="flex gap-2">
                    <PercentItem percent={percentage} />
                    {absChangeValue && (
                        <div className="flex gap-2 text-xs">
                            <span>/</span>
                            <span className="text-xs">
                                <ChangedBudgetItem value={absChangeValue} />
                            </span>
                        </div>
                    )}
                </span>
            )}
        </div>
    );
};
