import { BudgetRowValues } from '@/components/ModifyBudgetRow/ModifyBudgetRow';
import {
    useCampaignOptimizationData,
    UseCampaignOptimizationQueryResponse,
} from '@/dashboards/CampaignOptimization/hooks/useCampaignOptimizationData';
import { useDashboardGlobalContext } from '@/dashboards/providers/dashboardGlobalContext';
import moment from 'moment/moment';
import { ReactNode, useCallback, useState } from 'react';
import { createContext } from 'use-context-selector';

interface CampaignOptimizationContextValue {
    margin: number;
    onSubmit: SubmitFunc;
    variableValues: { [key: string]: BudgetRowValues };
    optimizationBase: 'media_kpi' | 'total_kpi' | 'budget';
    setOptimizationBase: (value: 'media_kpi' | 'total_kpi' | 'budget') => void;
    isLoading: boolean;
    isFetching: boolean;
    minDate: string;
    maxDate: string;
    totalContributionGraphPeriod: 'monthly' | 'weekly';
    setTotalContributionGraphPeriod: (value: 'monthly' | 'weekly') => void;
}

export const CampaignOptimizationContext = createContext<
    CampaignOptimizationContextValue &
        Pick<UseCampaignOptimizationQueryResponse, 'data'>
>({
    margin: 100,
    onSubmit: () => {},
    variableValues: {},
    optimizationBase: 'budget',
    setOptimizationBase: () => {},
    isLoading: false,
    isFetching: false,
    data: {
        previous: {
            totals: {
                contribution: 0,
                investment: 0,
            },
            totalContribution: 0,
            roiCurves: {
                curves: {},
                xAxis: [],
                variablesAndCategories: {
                    byVariable: {},
                    byCategory: {},
                },
            },
            contributionsByCategory: {},
            rows: [],
            mediaContribution: 0,
        },
        optimized: undefined,
        highestVariableValue: 0,
        highestCategoryValue: 0,
        suggestedBudgetData: [],
        budgetByCategoryData: {
            previous: [],
            simulated: [],
        },
        monthlyMediaInvestment: {
            monthlyMediaData: [],
            highestValue: 0,
        },
        initialAllocation: {},
        budgetByVariableData: {},
        contributionTotal: 0,
        previousPeriodInvestmentTotal: 0,
        simulatedInvestmentTotal: 0,
        diminishingPoints: {},
        getMediaBudgetTableData: () => [],
        contributionTotalWithNewMedia: 0,
        contributionData: {
            rows: [],
        },
        contributionLastYear: {
            data: {
                rows: [],
            },
            isLoading: false,
            isFetching: false,
        },
        expectedContributionByCategoryData: {},
    },
    minDate: '',
    maxDate: '',
    totalContributionGraphPeriod: 'monthly',
    setTotalContributionGraphPeriod: () => {},
});

interface SubmitFunc {
    (key: string, data: BudgetRowValues): void;
    (key: 'margin', data: number | null): void;
}

export const CampaignOptimizationContextProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [optimizationBase, setOptimizationBaseState] = useState<
        'media_kpi' | 'total_kpi' | 'budget'
    >('budget');

    const setOptimizationBase = useCallback(
        (value: 'media_kpi' | 'total_kpi' | 'budget') => {
            setOptimizationBaseState(value);
        },
        [],
    );
    const { endDate } = useDashboardGlobalContext();

    const [totalContributionGraphPeriod, setTotalContributionGraphPeriod] =
        useState<'monthly' | 'weekly'>('monthly');

    const minDate = moment(endDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
    const maxDate = moment(minDate, 'DD-MM-YYYY')
        .add(6, 'months')
        .format('DD-MM-YYYY');

    const { data, isLoading, isFetching, variableValues, setVariableValues } =
        useCampaignOptimizationData({
            maxDate,
            minDate,
            totalContributionGraphPeriod,
        });

    const [margin, setMargin] = useState<number>(100);

    const onSubmit: SubmitFunc = useCallback(
        (key, data) => {
            if (key === 'margin') {
                setMargin(data);
                return;
            }
            setVariableValues((prev) => ({
                ...prev,
                [key]: data,
            }));
        },
        [setVariableValues],
    );

    return (
        <CampaignOptimizationContext.Provider
            value={{
                margin,
                onSubmit,
                data,
                isLoading,
                isFetching,
                variableValues,
                optimizationBase,
                setOptimizationBase,
                minDate,
                maxDate,
                totalContributionGraphPeriod,
                setTotalContributionGraphPeriod,
            }}
        >
            {children}
        </CampaignOptimizationContext.Provider>
    );
};
