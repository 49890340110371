import { useConfigContext } from '@/context/configContext';
import { Typography, type TypographyVariant } from '@analytical-alley/ui';
import React from 'react';

interface Props {
    value: number;
    variant?: TypographyVariant;
    showArrows?: boolean;
}

export const ChangedBudgetItem = ({
    value,
    variant = 'bodyXS',
    showArrows = false,
}: Props) => {
    const { formatNumber } = useConfigContext();
    if (value > 0) {
        return (
            <div className="z-40 flex items-center text-xs font-semibold text-green-400 dark:text-green-400 text-center">
                {showArrows && (
                    <svg
                        className="w-[0.6rem] h-[0.6rem] ms-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 14"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13V1m0 0L1 5m4-4 4 4"
                        />
                    </svg>
                )}
                <Typography
                    className="text-green-400 dark:text-green-400"
                    variant={variant}
                >
                    {formatNumber(value)}
                </Typography>
            </div>
        );
    }

    if (value < 0) {
        return (
            <div className="flex items-center font-semibold text-center text-error dark:text-error">
                {showArrows && (
                    <svg
                        className="w-[0.6rem] h-[0.6rem]"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                )}
                <Typography
                    className="text-error dark:text-error"
                    variant={variant}
                >
                    {formatNumber(Math.abs(value))}
                </Typography>
            </div>
        );
    }

    return (
        <div className="ml-5 flex w-0 flex-1 items-center justify-end font-bold">
            <Typography
                className="text-cyan-300 dark:text-cyan-300"
                variant={variant}
            >
                {formatNumber(0)}
            </Typography>
        </div>
    );
};
