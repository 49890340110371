import { HorizontalBarGraph } from '@/components/HorizontalBarGraph';
import { WithLoader } from '@/components/WithLoader';
import { useConfigContext } from '@/context/configContext';
import { useModelContributionContext } from '@/dashboards/ModelContributions/hooks';
import { roundToDecimals } from '@/utils/numberUtils';
import { Tooltip, Typography } from '@analytical-alley/ui';
import { IconExclamationCircle } from '@tabler/icons-react';
import React from 'react';

export const ContributionByCategory = () => {
    const { formatNumber } = useConfigContext();
    const {
        queryResult: { isLoading, isFetching },
        selected,
        handleSelectedChange,
        contributionData: {
            totalContribution,
            totalContributionByCategory,
            categoryData,
        },
        selectedFilterNodes,
    } = useModelContributionContext();

    const sortedCategoryData = categoryData.sort(
        (a, b) =>
            totalContributionByCategory.get(b.category)! -
            totalContributionByCategory.get(a.category)!,
    );

    const dataToShow = sortedCategoryData.filter(
        (value) => selectedFilterNodes[value.category],
    );

    return (
        <div className="glass-tile p-6">
            <div className="flex justify-between mb-4">
                <Typography variant="titleS">
                    Model contribution by category
                </Typography>
                <Tooltip placement="bottom-end">
                    <Tooltip.Trigger>
                        <span className="cursor-pointer">
                            <IconExclamationCircle className="w-5" />
                        </span>
                    </Tooltip.Trigger>
                    <Tooltip.Content className="tooltip-content">
                        <Typography variant="bodyM" color="black">
                            To select a period, use the period filter above
                        </Typography>
                        <Typography variant="bodyM" color="black">
                            The view can be displayed in either variable or
                            group view, use the &quot;Model breakdown&quot;
                            option above
                        </Typography>
                        <Typography variant="bodyM" color="black">
                            To view only specific variables or groups, use the
                            &quot;Filter&quot; option
                        </Typography>
                        <Typography variant="bodyM" color="black">
                            <b>Contribution</b>-variable impact on a business
                            outcome (e.g.,how much a specific factor drives KPI
                            (revenue/sales/conversions/leads))
                        </Typography>
                    </Tooltip.Content>
                </Tooltip>
            </div>
            <WithLoader
                className={isLoading ? 'min-h-96' : undefined}
                isLoading={isLoading}
                isFetching={isFetching}
            >
                <HorizontalBarGraph>
                    {dataToShow.map((value) => {
                        const contribution = totalContributionByCategory.get(
                            value.category,
                        )!;
                        const percent = roundToDecimals(
                            (contribution / totalContribution) * 100,
                        );

                        return (
                            <HorizontalBarGraph.Row
                                onSelect={() => {
                                    handleSelectedChange(
                                        selected === value.category
                                            ? ''
                                            : value.category,
                                    );
                                }}
                                activeTitle={selected}
                                color={value.color}
                                key={`${value.category}-${value.category}`}
                                title={value.category}
                                percent={percent}
                                value={formatNumber(contribution)}
                            />
                        );
                    })}
                </HorizontalBarGraph>
            </WithLoader>
        </div>
    );
};
