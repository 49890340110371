import { INTERVAL_WEEKLY } from '@/config/constants';
import moment from 'moment/moment';

export const calculateMaxDate = (endDate: string, interval: string) => {
    const modelMaxDate = moment.utc(endDate, 'DD-MM-YYYY');

    if (interval === INTERVAL_WEEKLY) {
        return modelMaxDate.add(6, 'days').toDate();
    }

    return modelMaxDate.toDate();
};
